<template>
  <!-- 盘点移库过户新增 -->
  <div class="transferOwnershipAdd page-info-content">
    <el-descriptions class="margin-top" title="基本信息" :column="2" size="medium">
      <el-descriptions-item label="仓库名称">
        {{ inventoryDetailObj.warehouseName }}
      </el-descriptions-item>
      <el-descriptions-item label="货主名称">
        {{ inventoryDetailObj.custName }}
      </el-descriptions-item>
      <!-- 过户时才有的 -->
      <el-descriptions-item v-if="activePage === 3" label="新货主名称" label-class-name="setred">
        <el-select
          v-model="stockDetail.custId"
          class="cmpNameSearch"
          filterable
          reserve-keyword
          placeholder="请输入关键字搜索"
          :remote-method="remoteMethod"
        >
          <el-option v-for="item in cmpNameArr" :key="item.cmpCode" :label="item.cmpName" :value="item.cmpCode" />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item :label="`${activePage === 2 ? '移库' : activePage === 3 ? '过户' : '盘点'}日期`" label-class-name="setred">
        <el-date-picker
          v-model="stockDetail.operationDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptionsStart"
        />
      </el-descriptions-item>
      <el-descriptions-item label="操作人" label-class-name="setred">
        <el-input v-model="stockDetail.operator" placeholder="请输入操作人" />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 移库时才有的 -->
    <div v-if="activePage === 2" class="specificItem">
      <el-descriptions class="margin-top" title="货物明细" :column="3" size="medium" />
      <div class="tableLimitedBox">
        <Table :item-data="relocationitemData" :list-data="inventoryDetailObj.wmsInventorydetailRespList" :show-index="true">
          <template #newGoodsYardNo="{ row, index }">
            <el-select
              v-model="row.newGoodsYardNo"
              placeholder="请选择"
              @change="(val) => getAllocation(val, index)"
            >
              <el-option
                v-for="item in goodsAllocationOption"
                :key="item.goodsYardNo"
                :label="item.goodsYardNo"
                :value="item.goodsYardNo"
              />
            </el-select>
          </template>
          <template #detailInvWeight="{ row }">
            <span>{{ row.detailInvWeight }}{{ row.unit }}</span>
          </template>
          <template #newLocationNo="{ row, index }">
            <el-select
              v-model="row.newLocationNo"
              placeholder="请选择"
              @change="(val) => getNewLocationNo(val, index)"
            >
              <el-option
                v-for="item in row.locationNoOption"
                :key="item.locationNo"
                :label="item.locationNo"
                :value="item.locationNo"
              />
            </el-select>
          </template>
          <template #invWeight="{ row }">
            <el-input-number
              v-model="row.invWeight"
              :precision="3"
              :controls="false"
              size="small"
              placeholder="请输入"
              :min="0"
              :max="row.detailInvWeight"
              @input="totalInv(row, 2)"
            />
          </template>
        </Table>
      </div>
      <div class="buttonCombination">
        <el-button :loading="$store.state.loading" class="button" size="small" type="primary" @click="shiftingParking">
          确定移库
        </el-button>
        <router-link :to="{ path: '/warehousingSystem/inventoryManagement' }">
          <el-button size="small" type="primary" plain>
            取消
          </el-button>
        </router-link>
      </div>
    </div>
    <!-- 过户时才有的 -->
    <div v-if="activePage === 3" class="specificItem">
      <el-descriptions class="margin-top" title="货位明细" :column="3" size="medium" />
      <div class="tableLimitedBox">
        <Table :item-data="transferitemData" :list-data="inventoryDetailObj.wmsInventorydetailRespList" :show-index="true">
          <template #invWeight="{ row }">
            <span>{{ row.invWeight }}{{ row.unit }}</span>
          </template>
          <template #frozenWight="{ row }">
            <span>{{ row.frozenWight }}{{ row.unit }}</span>
          </template>
          <template #avlbWeight="{ row }">
            <span>{{ row.avlbWeight }}{{ row.unit }}</span>
          </template>
          <template #transferWeight="{ row, index }">
            <div class="flex-row">
              <span class="number">{{ row.transferWeight }}</span>
              <i class="el-icon-edit" @click="dialogTransfer(row, index)" />
            </div>
          </template>
        </Table>
      </div>
      <div class="buttonCombination">
        <el-button :loading="$store.state.loading" class="button" size="small" type="primary" @click="massTransfer">
          确定过户
        </el-button>
        <router-link :to="{ path: '/warehousingSystem/inventoryManagement' }">
          <el-button size="small" type="primary" plain>
            取消
          </el-button>
        </router-link>
      </div>
    </div>
    <!-- 盘点时才有的 -->
    <div v-if="activePage === 4" class="specificItem">
      <el-descriptions class="margin-top" title="货位明细" :column="3" size="medium" />
      <div class="tableLimitedBox">
        <Table :item-data="checkitemData" :list-data="inventoryDetailObj.wmsInventorydetailRespList" :show-index="true">
          <template #detailInvWeight="{ row }">
            <span>{{ row.detailInvWeight }}{{ row.unit }}</span>
          </template>
          <template #invWeight="{ row }">
            <el-input-number
              v-model="row.invWeight"
              :precision="3"
              :controls="false"
              size="small"
              placeholder="请输入"
              :min="0"
            />
          </template>
          <template #differencesNum="{ row }">
            <span v-if="row.invWeight || row.invWeight === 0" :class="(subPrecision((row.invWeight || 0), row.detailInvWeight)) > 0 ? 'green' : (subPrecision((row.invWeight || 0), row.detailInvWeight)) < 0 ? 'red' : ''">{{ (subPrecision((row.invWeight || 0), row.detailInvWeight)) }}</span>
          </template>
          <template #result="{ row }">
            <span v-if="row.invWeight || row.invWeight === 0" :class="(subPrecision((row.invWeight || 0), row.detailInvWeight)) > 0 ? 'green' : (subPrecision((row.invWeight || 0), row.detailInvWeight)) < 0 ? 'red' : ''">{{ (subPrecision((row.invWeight || 0), row.detailInvWeight)) > 0 ? '盘盈' : (subPrecision((row.invWeight || 0), row.detailInvWeight)) === 0 ? '相符' : '盘亏' }}</span>
          </template>
        </Table>
      </div>
      <div class="buttonCombination">
        <el-button :loading="$store.state.loading" class="button" size="small" type="primary" @click="determineInventory">
          确定盘点
        </el-button>
        <router-link :to="{ path: '/warehousingSystem/inventoryManagement' }">
          <el-button size="small" type="primary" plain>
            取消
          </el-button>
        </router-link>
      </div>
    </div>
    <ProductNamePopUpLayer :id="pnid" ref="ProductNamePopUpLayer" />
    <TransferQuantity ref="transferQuantity" :list-data="transferChildItem" @getTransferData="getTransferData" />
  </div>
</template>

<script>
import { getCmpBaseinfoPassList } from '../../../api/common'
import ProductNamePopUpLayer from '../components/ProductNamePopUpLayer'
import { inventoryTransferAdd, inventoryCheck, inventoryMove, warehouseGetYarnAndArea } from '../../../api/warehousingSystem'
import TransferQuantity from '../components/TransferQuantity.vue'
import Table from '@/components/Table'
import { subPrecision } from '@/unit/index'
export default {
  components: { ProductNamePopUpLayer, TransferQuantity, Table },
  data() {
    return {
      subPrecision,
      pnid: '',
      stockDetail: {
        wmsInventoryTrsdetailReqList: []
      },
      activePage: 0,
      cmpNameArr: [],
      inventoryDetailObj: {},
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      transferQuantityVisible: false,
      relocationitemData: [
        { label: '品名', prop: 'detailGoodsName', width: 180 },
        { label: '材质', prop: 'detailMaterials', width: 180 },
        { label: '规格', prop: 'detailSpecs', width: 180 },
        { label: '产地', prop: 'detailProductions', width: 180 },
        { label: '件重(吨)', prop: 'detailWeights', width: 180 },
        { label: '库存数量/单位', prop: 'detailInvWeight', width: 180 },
        { label: '现库区编号', prop: 'goodsYardNo', width: 180 },
        { label: '现货位号', prop: 'detailLocationNo', width: 180 },
        { label: '新库区编号', prop: 'newGoodsYardNo', width: 180, required: true },
        { label: '新货位号', prop: 'newLocationNo', width: 180, required: true },
        { label: '移库数量', prop: 'invWeight', width: 180, required: true }
      ],
      transferitemData: [
        { label: '品名', prop: 'goodsName', width: 180 },
        { label: '材质', prop: 'materials', width: 180 },
        { label: '规格', prop: 'specs', width: 180 },
        { label: '产地', prop: 'productions', width: 180 },
        { label: '件重(吨)', prop: 'weights', width: 180 },
        { label: '库存数量/单位', prop: 'invWeight', width: 120 },
        { label: '冻结数量/单位', prop: 'frozenWight', width: 120 },
        { label: '可用数量/单位', prop: 'avlbWeight', width: 120 },
        { label: '过户数量', prop: 'transferWeight', width: 220, required: true }
      ],
      checkitemData: [
        { label: '品名', prop: 'detailGoodsName', width: 120 },
        { label: '材质', prop: 'detailMaterials', width: 120 },
        { label: '规格', prop: 'detailSpecs', width: 120 },
        { label: '产地', prop: 'detailProductions', width: 180 },
        { label: '件重(吨)', prop: 'detailWeights', width: 120 },
        { label: '库区编号', prop: 'goodsYardNo', width: 120 },
        { label: '货位号', prop: 'detailLocationNo', width: 120 },
        { label: '库存数量/单位', prop: 'detailInvWeight', width: 120 },
        { label: '实盘数量', prop: 'invWeight', width: 120, required: true },
        { label: '差异数', prop: 'differencesNum', width: 120 },
        { label: '结果', prop: 'result', width: 120 }
      ],
      goodsAllocationOption: [], // 库区编号合集
      transferChildItem: [], // 过户编辑数据
      transferDataIndex: null,
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/inventoryManagement', name: '库存管理' }, { path: '', name: '过户新增' }]
    }
  },
  async mounted() {
    if (this.$route.query.type) {
      this.activePage = parseInt(this.$route.query.type)
      switch (this.activePage) {
        case 2:
          this.breadcrumbItem = [{ path: '/', name: '首页' }, { path: '/warehousingSystem/inventoryManagement', name: '库存管理' }, { path: '', name: '移库' }]
          break
        case 4:
          this.breadcrumbItem = [{ path: '/', name: '首页' }, { path: '/warehousingSystem/inventoryManagement', name: '库存管理' }, { path: '', name: '盘点' }]
          break
      }
      this.inventoryDetailObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.resourceByTaskObj)
      if (this.inventoryDetailObj.warehouseId) this.addGoodsAllocation()
      // inventoryDetail({ id: this.$route.query.id }, res => {
      //   this.inventoryDetailObj = { ...res.data }
      //   this.pnid = this.inventoryDetailObj.goodsWeightsId
      // })
    }
    this.remoteMethod()
    this.getDate()
  },
  methods: {
    getDate() {
      var now = new Date()
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() + 1 // 得到月份
      var date = now.getDate() // 得到日期
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.$set(this.stockDetail, 'operationDate', defaultDate)
    },
    // 判断不能是原货位号
    notSame(item) {
      if (item.newLocationNo === item.detailLocationNo) {
        this.$message.error('不能与原货位号相同')
      }
      // 判断重复了就为空
      this.$set(item, 'newLocationNo', item.newLocationNo === item.detailLocationNo ? '' : item.newLocationNo)
    },
    // 确定移库
    shiftingParking() {
      if (!this.stockDetail.operationDate) return this.$message.error('请完善移库日期')
      if (!this.stockDetail.operator) return this.$message.error('请完善操作人')
      if (this.stockDetail.operator.length > 15) return this.$message.error('操作人不得超过15个字符')
      this.stockDetail.wmsInventoryMoveReqList = []
      this.stockDetail.wmsInventoryMoveReqList = this.inventoryDetailObj.wmsInventorydetailRespList.filter(item => {
        item.weights
        item.newGoodsYardNo
        item.newLocationNo
        item.goodsYardRemark
        item.locationDesc
        item.coilNo = item.detailCoilNo
        item.inventoryDetailId = item.detailLocationDesc
        item.inventoryId = item.detailInventoryId
        item.locationNo = item.detailLocationNo
        item.materials = item.detailMaterials
        item.newWarehouseNo = item.detailWarehouseNo
        item.oldWarehouseNo = item.detailWarehouseNo
        item.productions = item.detailProductions
        item.specs = item.detailSpecs
        item.srcInvAmount = item.detailInvAmount
        item.srcInvWeight = item.detailInvWeight
        item.srcInventoryId = item.detailInventoryId
        item.srcGoodsYardNo = item.goodsYardNo
        item.srcLocationNo = item.detailLocationNo
        item.warehouseNo = item.detailWarehouseNo
        // item.weights = item.detailWeights
        return (item.invWeight || item.invWeight === 0) && item.newLocationNo && item.newGoodsYardNo
      })
      if (this.stockDetail.wmsInventoryMoveReqList.length === 0) {
        this.$message.error('请完善至少一条货物信息')
        return
      }
      for (let i = 0; i < this.stockDetail.wmsInventoryMoveReqList.length; i++) {
        if ((this.stockDetail.wmsInventoryMoveReqList[i].newGoodsYardNo === this.stockDetail.wmsInventoryMoveReqList[i].goodsYardNo) && (this.stockDetail.wmsInventoryMoveReqList[i].newLocationNo === this.stockDetail.wmsInventoryMoveReqList[i].detailLocationNo)) {
          return this.$message.error(`货物信息里序号第${i + 1}条新库区和新货位号不能与原数据一模一样，请检查`)
        }
        if (this.stockDetail.wmsInventoryMoveReqList[i].invWeight === 0) {
          return this.$message.error(`移库数量为0，无法移库`)
        }
      }
      const params = { ...this.stockDetail }
      params.moveDate = params.operationDate
      params.warehouseId = this.inventoryDetailObj.warehouseId
      params.custId = this.inventoryDetailObj.custId
      params.warehouseName = this.inventoryDetailObj.warehouseName
      params.custName = this.inventoryDetailObj.custName
      this.$confirm('此操作将进行移库, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        inventoryMove(params, () => {
          this.$message.success('成功！')
          this.$router.push('/warehousingSystem/shiftingParking')
        })
      }).catch(() => {
      })
    },
    // 确定盘点
    determineInventory() {
      if (!this.stockDetail.operationDate) return this.$message.error('请完善过户日期')
      if (!this.stockDetail.operator) return this.$message.error('请完善操作人')
      if (this.stockDetail.operator.length > 15) return this.$message.error('操作人不得超过15个字符')
      this.stockDetail.wmsInventoryCheckReqList = []
      for (let i = 0; i < this.inventoryDetailObj.wmsInventorydetailRespList.length; i++) {
        if (this.inventoryDetailObj.wmsInventorydetailRespList[i].invWeight <= 0 || !this.inventoryDetailObj.wmsInventorydetailRespList[i].invWeight) return this.$message.error('请完善所有商品的盘点数据')
        this.stockDetail.wmsInventoryCheckReqList.push({
          detailId: this.inventoryDetailObj.wmsInventorydetailRespList[i].detailId,
          invAmount: 0,
          invWeight: this.inventoryDetailObj.wmsInventorydetailRespList[i].invWeight,
          srcInventoryId: this.inventoryDetailObj.wmsInventorydetailRespList[i].detailInventoryId
        })
      }
      const params = { ...this.stockDetail }
      params.checkDate = params.operationDate
      params.warehouseId = this.inventoryDetailObj.warehouseId
      params.custId = this.inventoryDetailObj.custId
      params.warehouseName = this.inventoryDetailObj.warehouseName
      params.custName = this.inventoryDetailObj.custName
      this.$confirm('此操作将进行盘点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        inventoryCheck(params, () => {
          this.$message.success('成功！')
          this.$router.push('/warehousingSystem/takeStock')
        })
      }).catch(() => {
      })
    },
    // 批量过户
    massTransfer() {
      if (!this.stockDetail.custId) return this.$message.error('请选择新货主名称！')
      if (!this.stockDetail.operationDate) return this.$message.error('请完善过户日期')
      if (!this.stockDetail.operator) return this.$message.error('请完善操作人')
      if (this.stockDetail.operator.length > 15) return this.$message.error('操作人不得超过15个字符')

      // this.stockDetail.wmsInventoryTrsdetailReqList = []
      // this.stockDetail.wmsInventoryTrsdetailReqList = this.inventoryDetailObj.wmsInventorydetailRespList.filter(item => {
      //   item.detailId
      //   item.invWeight
      //   item.srcInventoryId = item.detailInventoryId
      // item.coilNo = item.detailCoilNo
      // item.inventoryDetailId = item.detailLocationDesc
      // item.inventoryId = item.detailInventoryId
      // item.locationDesc = item.detailLocationDesc
      // item.locationNo = item.detailLocationNo
      // item.materials = item.detailMaterials
      // item.newWarehouseNo = item.detailWarehouseNo
      // item.oldWarehouseNo = item.detailWarehouseNo
      // item.productions = item.detailProductions
      // item.specs = item.detailSpecs
      // item.srcInvAmount = item.detailInvAmount
      // item.srcInvWeight = item.detailInvWeight
      // item.srcInventoryId = item.detailInventoryId
      // item.srcLocationNo = item.detailLocationNo
      // item.warehouseNo = item.detailWarehouseNo
      // item.weights = item.detailWeights
      //   return parseInt(item.invWeight) > 0
      // })
      // if (this.stockDetail.wmsInventoryTrsdetailReqList.length === 0) {
      //   this.$message.error('请填写完整的的数据')
      //   return
      // }
      const detailsArr = []
      this.inventoryDetailObj.wmsInventorydetailRespList.map((item) => {
        detailsArr.push(...item.wmsInventorydetailRespList)
      })
      const newDetalis = detailsArr.filter((item) => {
        item.detailId
        item.goodsYardNo
        item.goodsYardRemark
        item.invWeight = item.transferWeight || 0
        item.locationDesc = item.detailLocationDesc
        item.locationNo = item.detailLocationNo
        item.srcInventoryId = item.detailInventoryId
        item.invAmount = 0
        return (item.invWeight) > 0
      })
      if (newDetalis.length === 0) {
        this.$message.error('请完善至少一条货物信息')
        return
      }
      this.stockDetail.wmsInventoryTrsdetailReqList = newDetalis

      // 获取新货主企业的名字
      this.cmpNameArr.forEach(item => {
        if (item.cmpCode === this.stockDetail.custId) this.stockDetail.custName = item.cmpName
      })
      const params = { ...this.stockDetail }
      params.transferDate = params.operationDate
      params.warehouseId = this.inventoryDetailObj.warehouseId
      params.oldCustId = this.inventoryDetailObj.custId // 老货主id
      params.warehouseName = this.inventoryDetailObj.warehouseName
      params.oldCustName = this.inventoryDetailObj.custName // 老货主name
      params.weight = this.stockDetail.wmsInventoryTrsdetailReqList.reduce((prev, current) => prev + (current.invWeight || 0), 0)
      if (params.weight === 0 || !params.weight) return this.$message.error('过户数量为0，无法过户')
      this.$confirm('此操作将过户给新货主, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        inventoryTransferAdd(params, () => {
          this.$message.success('成功！')
          this.$router.push('/warehousingSystem/transferOwnership')
        })
      }).catch(() => {
      })
    },
    // 输入数量跟数量联动
    totalInv(item, index) {
      // 判断不能超过库存
      // if (index === 1) {
      //   this.$set(item, 'invAmount', parseInt(item.detailInvAmount) < parseInt(item.invAmount) ? item.detailInvAmount : item.invAmount)
      // } else if (index === 2) {
      //   this.$set(item, 'invWeight', parseInt(item.detailInvWeight) < parseInt(item.invWeight) ? item.detailInvWeight : item.invWeight)
      // }
      const invAmount = 0; const invWeight = 0
      // this.inventoryDetailObj.wmsInventorydetailRespList.forEach(item => {
      //   invAmount += Number(item.invAmount) || 0
      //   invWeight += Number(item.invWeight) || 0
      // })
      this.stockDetail.amount = invAmount
      this.stockDetail.weight = invWeight
    },
    open() {
      this.$refs.ProductNamePopUpLayer.dialogTableVisible = true
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoPassList(value || '', res => {
        this.cmpNameArr = [...res.data]
      })
    },
    // 根据仓库id查询区号、货位号
    addGoodsAllocation() {
      warehouseGetYarnAndArea(this.inventoryDetailObj.warehouseId || '', res => {
        if (!res.data.id || !res.data.wmsYardRespList) return
        this.goodsAllocationOption = [...res.data.wmsYardRespList]
      })
    },
    // 选择关联库区
    getAllocation(val, index) {
      if (!val) return
      this.goodsAllocationOption.forEach((item) => {
        if (item.goodsYardNo === val) {
          this.inventoryDetailObj.wmsInventorydetailRespList[index].locationNoOption = item.wmsAreaRespList
          this.inventoryDetailObj.wmsInventorydetailRespList[index].goodsYardRemark = item.goodsYardRemark
        }
      })
      this.$set(this.inventoryDetailObj.wmsInventorydetailRespList[index], 'newLocationNo', '')
    },
    // 选择货位号
    getNewLocationNo(val, index) {
      this.inventoryDetailObj.wmsInventorydetailRespList[index].locationNoOption.forEach((item) => {
        if (item.locationNo === val) {
          this.inventoryDetailObj.wmsInventorydetailRespList[index].locationDesc = item.locationDesc
        }
      })
    },
    // 编辑过户数量
    dialogTransfer(row, index) {
      this.$refs.transferQuantity.transferQuantityVisible = true
      this.transferChildItem = JSON.parse(JSON.stringify(row.wmsInventorydetailRespList))
      this.transferChildItem.map((item) => {
        item.avlbWeight = row.avlbWeight
      })
      this.transferDataIndex = index
    },
    // 获取数据编辑过户数量
    getTransferData(value) {
      let transferWeight = 0
      transferWeight = value.reduce((prev, current) => prev + current.transferWeight, 0)
      this.$set(this.inventoryDetailObj.wmsInventorydetailRespList[this.transferDataIndex], 'transferWeight', transferWeight)
      this.$set(this.inventoryDetailObj.wmsInventorydetailRespList[this.transferDataIndex], 'wmsInventorydetailRespList', value)
    }
  }
}
</script>

<style>
.transferOwnershipAdd .buttonCombination {
  text-align: center;
  margin-top: 32px;
}

.transferOwnershipAdd .el-descriptions {
  padding: 40px 32px 0 !important;
}

.transferOwnershipAdd .specificItem {
  padding: 16px 0;
  width: 100%;
  overflow-y: auto;
}

.transferOwnershipAdd .specificItem .el-descriptions {
  padding: 0 32px !important;
}

.transferOwnershipAdd .buttonCombination .button {
  margin-right: 32px;
}

.transferOwnershipAdd .tableBox .el-input,
.transferOwnershipAdd .tableBox .el-input .el-input__inner {
  border: 0;
  text-align: center;
}
.transferOwnershipAdd .el-descriptions-item__content .el-select,
.transferOwnershipAdd .el-descriptions-item__content input {
  height: 32px;
  width: 260px;
  /* height: 32px;
  border: 1px solid #ededed;
  outline: 0;
  transform: translateY(-10px);
  text-indent: 6px; */
}
.transferOwnershipAdd .el-descriptions-item__content .el-selete,
.transferOwnershipAdd .el-descriptions-item__content .el-input__icon {
    line-height: 32px;
  }
.transferOwnershipAdd .el-descriptions {
  padding: 40px 32px;
}

.transferOwnershipAdd .el-descriptions:last-of-type {
  padding: 0 32px;
}

.transferOwnershipAdd .el-descriptions-row td {
  padding-bottom: 28px !important;
}
.transferOwnershipAdd  .tableLimitedBox{
  width: calc(100% - 32px);
  max-height: 660px;
  padding-left: 32px;
  overflow-y: auto;
}
.transferOwnershipAdd .tableBox {
  text-align: center;
  border-collapse: collapse;
  /* width: 160%; */
  font-size: 13px;
}

.transferOwnershipAdd .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
  width: 200px;
}

.transferOwnershipAdd .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}

.transferOwnershipAdd .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
.flex-row {
  display: flex;
  align-items: center;
}
.flex-row .el-icon-edit {
  font-size: 24px;
  cursor: pointer;
}
.flex-row .number {
  flex: 1;
}
.red {
  color: red;
}
.green {
  color: green;
}
.setred::before{
  content: "*";
  color: red;
  font-weight: bold;
  font-size: 16px;
}
</style>
